<template>
  <div class="box-page-new">
    <div class="tab__menus">
      <tabs-menu>
        <template v-slot:header>
          <div class="tabs-link active">
            <li>
              {{ $t("menu.doctor_report") }}
            </li>
          </div>
          <router-link to="/doctor/summary-report/fee" class="tabs-link">
            <li>
              {{ $t("menu.revenue_report") }}
            </li>
          </router-link>
        </template>
      </tabs-menu>
    </div>
    <div
      class="tab__contents"
      :class="{ 'tab__contents--active': activeTab == 1 }"
    >
      <div class="tab__selects">
        <v-select
          @change="currentDataItems"
          v-model="activeTab"
          :items="listTab"
          item-text="title"
          item-value="id"
          dense
        />
      </div>
    </div>
    <div>
      <v-row class="content-table">
        <v-col cols="12" md="2" class="d-flex align-center">
          <v-select
            v-model="dataPerPage"
            :items="listPerPage"
            item-text="text"
            item-value="value"
            class=""
            :label="$t('per_page')"
            dense
            hide-details
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" class="d-flex align-center">
          <v-dialog
            ref="dialog"
            v-model="modalStartDate"
            :return-value.sync="start_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mt-0"
                v-model="dateFormatedStart"
                :label="$t('start_date')"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                hide-details
                append-icon="mdi-calendar"
              ></v-text-field>
            </template>
            <v-date-picker
            :locale="$i18n.locale"
              v-model="start_date"
              :max="
                new Date(
                  new Date(end_date != '' ? end_date : null) -
                    new Date().getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .substr(0, 10)
              "
              no-title
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalStartDate = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn text color="primary" @click="changeFilterDate">
                {{ $t("ok") }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <span class="mx-2">-</span>
          <v-dialog
            ref="dialogend"
            v-model="modalEndDate"
            :return-value.sync="end_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mt-0"
                v-model="dateFormatedEnd"
                :label="$t('end_date')"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                hide-details
                append-icon="mdi-calendar"
              ></v-text-field>
            </template>
            <v-date-picker
            :locale="$i18n.locale"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
              :min="
                new Date(
                  new Date(start_date != '' ? start_date : null) -
                    new Date().getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .substr(0, 10)
              "
              v-model="end_date"
              no-title
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalEndDate = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn text color="primary" @click="changeFilterDate">
                {{ $t("ok") }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="4" class="d-flex align-center justify-end">
          <v-text-field
            class="searchField"
            outlined
            dense
            hide-details
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search') + ' ...'"
            single-line
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn
            class="button-box button--outline mr-4"
            outlined
            color="teal"
            @click="getMonthClick"
            v-if="checkItemMonth == false"
          >
            {{ $t("this_month") }}
          </v-btn>
          <v-btn
            class="button-box button--active mr-4"
            color="primary"
            dark
            @click="getMonthClick"
            v-if="checkItemMonth == true"
          >
            {{ $t("this_month") }}
          </v-btn>
          <v-btn
            class="button-box button--outline"
            outlined
            color="teal"
            @click="getYearClick"
            v-if="checkItemYear == false"
          >
            {{ $t("this_year") }}
          </v-btn>
          <v-btn
            class="button-box button--active"
            color="primary"
            dark
            @click="getYearClick"
            v-if="checkItemYear == true"
          >
            {{ $t("this_year") }}
          </v-btn>
        </v-col>
        <v-col cols="12" class="mt-3">
          <div class="table-responsive">
            <v-data-table
              :headers="headerTable"
              :items="doctorList"
              hide-default-footer
              :items-per-page="dataPerPage"
              :page.sync="pageDataTable"
              class="elevation-1"
              @page-count="pageCountDataTable = $event"
              :mobile-breakpoint="0"
              :search="search"
            >
              <!-- header -->
              <template v-slot:[`header.refer_number`]="{ header }">
                <HeadingTable :title="header.text" :subTitle="header.textEn" />
              </template>
              <template v-slot:[`header.order_number`]="{ header }">
                <HeadingTable :title="header.text" :subTitle="header.textEn" />
              </template>
              <template v-slot:[`header.patient_id_number`]="{ header }">
                <HeadingTable :title="header.text" :subTitle="header.textEn" />
              </template>
              <template v-slot:[`header.patient`]="{ header }">
                <HeadingTable :title="header.text" :subTitle="header.textEn" />
              </template>

              <template v-slot:[`header.registration_date`]="{ header }">
                <HeadingTable :title="header.text" :subTitle="header.textEn" />
              </template>
              <template v-slot:[`header.plant`]="{ header }">
                <HeadingTable :title="header.text" :subTitle="header.textEn" />
              </template>
              <template v-slot:[`header.product_order`]="{ header }">
                <HeadingTable :title="header.text" :subTitle="header.textEn" />
              </template>
              <!-- body -->
              <template v-slot:item="{ item, index }">
                <tr>
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.refer_number }}</td>
                  <td>{{ item.order_number }}</td>
                  <td>{{ item.patient_id_number }}</td>
                  <td>{{ item.patient }}</td>
                  <td>{{ formatDate(item.registration_date) }}</td>
                  <td>
                    <p class="list-table">{{ item.plant }}</p>
                  </td>
                  <td>
                    <span
                      v-for="(service, idperdata) in item.product_order"
                      :key="idperdata"
                      ><p class="list-table">
                        {{ service
                        }}{{
                          item.product_order.length - 1 == idperdata
                            ? "."
                            : ", "
                        }}
                      </p>
                    </span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <v-row class="mt-6">
            <v-col cols="6" class="d-flex justify-start">
              <v-pagination
                :total-visible="5"
                v-model="pageDataTable"
                :length="pageCountDataTable"
              ></v-pagination>
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <button @click="reloadPage" class="button-box button--download">
                <vue-excel-xlsx
                  :data="doctorList"
                  :columns="columns"
                  :filename="fileNameExcel"
                >
                  {{ $t("download") }}
                </vue-excel-xlsx>
                <v-icon dark right> mdi-download </v-icon>
              </button>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// import TabContent from "@/components/v2/tabs/index.vue";
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import HeadingTable from "@/components/Heading/HeadingTable.vue";
import TabsMenu from "@/components/general/TabsMenu.vue";

export default {
  components: { TabsMenu, HeadingTable },
  data: () => ({
    activeTab: 1,
    checkItemMonth: false,
    checkItemYear: false,
    pageDataTable: 1,
    pageCountDataTable: 2,
    dataPerPage: 10,
    modalStartDate: false,
    modalEndDate: false,
    start_date: "",
    end_date: "",
    start_date_filter: "",
    end_date_filter: "",
    now: "",
    search: "",
    doctorList: [],
    fileNameExcel: "",
    // totalOrder: 0,
    // totalRevenue: 0,
    listPerPage: [
      {
        value: -1,
        text: "All",
      },
      {
        value: 10,
        text: "10",
      },
      {
        value: 25,
        text: "25",
      },
      {
        value: 50,
        text: "50",
      },
    ],
    columns: [
      {
        label: "No Referal",
        field: "refer_number",
      },
      {
        label: "No Order",
        field: "order_number",
      },
      {
        label: "ID Pasien",
        field: "patient_id_number",
      },

      {
        label: "Nama Pasien",
        field: "patient",
      },
      {
        label: "Tanggal Pemesanan",
        field: "registration_date",
      },
      {
        label: "Cabang Virtu DigiLab",
        field: "plant",
      },
      {
        label: "Pemeriksaan Layanan",
        field: "product_order",
      },
    ],
    headerTable: [
      {
        value: "index",
        text: "No.",
        textEn: "No.",
        align: "center",
        sortable: false,
      },
      {
        text: "No Referal",
        textEn: "Referral Number",
        value: "refer_number",
        align: "center",
      },
      {
        text: "No Order",
        textEn: "Order Number",
        value: "order_number",
        align: "center",
      },
      {
        text: "ID Pasien",
        textEn: "Patient ID",
        value: "patient_id_number",
        align: "center",
      },
      {
        text: "Nama Pasien",
        textEn: "Patient Name",
        value: "patient",
        align: "center",
      },
      {
        text: "Tanggal Pemesanan",
        textEn: "Booking Date",
        value: "registration_date",
        align: "center",
      },
      {
        text: "Cabang Virtu DigiLab",
        textEn: "Virtu DigiLab Branch",
        value: "plant",
        align: "center",
      },
      {
        text: "Pemeriksaan Layanan",
        textEn: "Service Test",
        value: "product_order",
        align: "center",
        sortable: false,
      },
    ],
    listTab: [
      {
        id: 1,
        title: "Referral Report",
      },
      {
        id: 2,
        title: "Fee Report",
      },
    ],
  }),
  computed: {
    dateFormatedStart() {
      return this.formatDateNow(this.start_date);
    },
    dateFormatedEnd() {
      return this.formatDateNow(this.end_date);
    },
  },
  async mounted() {
    this.$store.commit("setLoading", true);
    let getDate = new Date();
    this.fileNameExcel = `doctor_report_${getDate.getFullYear()}${getDate.getMonth()}${getDate.getDate()}${getDate.getHours()}${getDate.getMinutes()}${getDate.getSeconds()}`;

    await API.get(`${process.env.VUE_APP_API_TRUST}referral/get-order`, {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then((res) => {
      if (res.statusCode == 200) {
        this.doctorList = res.results;
      }
    });
    this.now = this.formatDateNow(new Date());
    this.start_date = new Date(
      `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}`
    )
      .toISOString()
      .substr(0, 10);
    this.end_date = new Date().toISOString().substr(0, 10);
    this.$store.commit("setLoading", false);
  },
  methods: {
    currentDataItems() {
      if (this.activeTab == 1) {
        this.$router.push("/doctor/summary-report/referral");
      } else {
        this.$router.push("/doctor/summary-report/fee");
      }
    },
    getDateRange() {
      this.checkItemYear = false;
      this.checkItemMonth = false;

      API.get(`${process.env.VUE_APP_API_TRUST}referral/get-order`, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      }).then((res) => {
        this.start_date_filter = new Date(this.start_date).getTime();
        this.end_date_filter = new Date(this.end_date).getTime();

        const dateRangeFilter = res.results.filter((dateRangeData) => {
          const newDateRangeData = new Date(dateRangeData.registration_date);
          const setDateRange = `${newDateRangeData.getFullYear()}-${(
            "0" +
            (newDateRangeData.getMonth() + 1)
          ).slice(-2)}-${("0" + newDateRangeData.getDate()).slice(-2)}`;
          const dateRangeGetTime = newDateRangeData.getTime();

          if (setDateRange == this.start_date) {
            const setNewDateStart = new Date(this.start_date);
            const setStartFilter = `${setNewDateStart.getFullYear()}-${
              setNewDateStart.getMonth() + 1
            }-${setNewDateStart.getDate() - 1}`;
            const startFilterGetTime = new Date(setStartFilter).getTime();

            return dateRangeGetTime > startFilterGetTime;
          } else if (setDateRange == this.end_date) {
            const setDateFilterRange = `${newDateRangeData.getFullYear()}-${
              newDateRangeData.getMonth() + 1
            }-${newDateRangeData.getDate() - 1}`;
            const DateNewDateFilter = new Date(setDateFilterRange).getTime();
            const endFilterGetTime = new Date(this.end_date).getTime();

            return DateNewDateFilter < endFilterGetTime;
          } else {
            return (
              dateRangeGetTime > this.start_date_filter &&
              dateRangeGetTime < this.end_date_filter
            );
          }
        });
        this.doctorList = dateRangeFilter;
      });
    },
    formatDateNow(param) {
      if (!param) return null;
      const date = new Date(param);
      return `${("0" + date.getDate()).slice(-2)}-${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}-${date.getFullYear()}`;
    },
    changeFilterDate() {
      this.$refs.dialog.save(this.start_date);
      this.$refs.dialogend.save(this.end_date);
      this.getDateRange();
      this.modalStartDate = false;
      this.modalEndDate = false;
    },
    formatDate(value) {
      if (!value) return null;
      const MMM = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];
      const getDate = new Date(value);
      const formated = `${("0" + getDate.getDate()).slice(-2)} ${
        MMM[getDate.getMonth()]
      } ${getDate.getFullYear()} ${
        (getDate.getHours() < 10 ? "0" : "") + getDate.getHours()
      }:${(getDate.getMinutes() < 10 ? "0" : "") + getDate.getMinutes()}:${
        (getDate.getSeconds() < 10 ? "0" : "") + getDate.getSeconds()
      }`;
      return formated;
    },
    getYearClick() {
      const dateNow = new Date();
      const setYearNow = `${dateNow.getFullYear()}`;

      API.get(`${process.env.VUE_APP_API_TRUST}referral/get-order`, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      }).then((res) => {
        const yearFilter = res.results.filter((setDataYear) => {
          const newYearFilterData = new Date(setDataYear.registration_date);
          const setDateFormat = `${(
            "0" +
            (newYearFilterData.getMonth() + 1)
          ).slice(-2)}/${("0" + newYearFilterData.getDate()).slice(
            -2
          )}/${newYearFilterData.getFullYear()}`;
          const yearSlice = +setDateFormat.slice(-4);
          return setYearNow == yearSlice;
        });

        this.doctorList = yearFilter;
        this.checkItemYear = true;
        this.checkItemMonth = false;
      });
    },
    getMonthClick() {
      const dateNow = new Date();
      const setMonthYearNow = `${("0" + (dateNow.getMonth() + 1)).slice(
        -2
      )}-${dateNow.getFullYear()}`;

      API.get(`${process.env.VUE_APP_API_TRUST}referral/get-order`, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      }).then((res) => {
        const monthYearFilter = res.results.filter((setDataMonthYear) => {
          const newMonthYearFilterData = new Date(
            setDataMonthYear.registration_date
          );
          const setMonthYearFormat = `${(
            "0" +
            (newMonthYearFilterData.getMonth() + 1)
          ).slice(-2)}-${newMonthYearFilterData.getFullYear()}`;

          return setMonthYearNow == setMonthYearFormat;
        });

        this.doctorList = monthYearFilter;
        this.checkItemYear = false;
        this.checkItemMonth = true;
      });
    },
    reloadPage() {
      window.location.href = `doctor/summary-report/referral`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
.searchField {
  max-width: 500px;
}
.button--active {
  background: $main_3;
  color: white;
}
.button--outline {
  color: $main_3;
  border: 1px solid $main_3;
  background: white;
}
.button--download {
  background: #79c4c6;
  color: white;
}
.button-box {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  outline: none;
}
.card--result {
  background: $gray_3;
  padding: 18px;
  border-radius: 16px;
  width: 100%;
  // max-width: 300px;
  color: $gray_2;
  h4 {
    color: $gray_1;
    font-size: 20px;
  }
  p {
    margin: 0;
  }
  .card--result__item {
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray_2;
    &:nth-last-child(1) {
      border: none;
    }
  }
}

.status--inprogress {
  color: $yellow_1;
  font-weight: 600;
}
.status--complete {
  color: $green_1;
  font-weight: 600;
}
.status--cancel {
  color: $red_1;
  font-weight: 600;
}
.tabs {
  background: #e5e5e5 !important;
}
.content-table {
  padding: 35px 18px 18px 18px;
  @media screen and (max-width: 960px) {
    padding: 5px 18px 18px 18px;
  }
}
</style>
